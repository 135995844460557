<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <!-- 자재 목록 -->
      <c-table
        ref="grid"
        title="LBL0001709"
        tableId="grid"
        :columns="grid.columns"
        :data="grid.data"
        selection="multiple"
        rowKey="equipmentMaterialCd"
        :isFullScreen="false"
        :columnSetting="false"
        :filtering="false"
        :editable="editable"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <!-- 추가 -->
            <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="addrow" />
            <!-- 삭제 -->
            <c-btn v-if="editable" label="LBLREMOVE" icon="remove" @btnClicked="deleteMat" />
            <!-- 저장 -->
            <c-btn v-if="editable" label="LBLSAVE" icon="save" @btnClicked="saveMat" />
          </q-btn-group>
        </template>
      </c-table>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'equipment-bom',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      grid: {
        columns: [
          {
            name: 'processCd',
            field: 'processCd',
            valueName: 'codeName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            sortable: false,
            type: 'select',
            comboItems: this.param.processCds,
          },
          {
            name: 'materialName',
            field: 'materialName',
            // 자재명
            label: 'LBL0003254',
            align: 'center',
            sortable: false,
          },
          {
            name: 'chemMaterialFlag',
            field: 'chemMaterialFlag',
            // 화학자재 여부
            label: 'LBL0003255',
            align: 'center',
            sortable: false,
            type: 'check',
            true: 'Y',
            false: 'N'
          },
          {
            name: 'chemDailyVolume',
            field: 'chemDailyVolume',
            // 화학자재 일 취급량
            label: 'LBL0003327',
            align: 'center',
            type: 'text',
            sortable: false,
          },
          {
            name: 'chemDailyHour',
            field: 'chemDailyHour',
            // 화확자재 일 취급시간
            label: 'LBL0003328',
            align: 'center',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
      processItems: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
    param() {
      this.getList()
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.equipment.material.list.url;
      this.saveUrl = transactionConfig.mdm.equipment.material.save.url;
      this.deleteUrl = transactionConfig.mdm.equipment.material.delete.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {equipmentCd: this.param.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveMat() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let isDupl = false;
        this.$_.forEach(this.grid.data, item => {
          let _cnt = 0
          this.$_.forEach(this.grid.data, _item => {
            if (item.materialCd == _item.materialCd && item.processCd == _item.processCd) {
              _cnt++;
            }
          });
          if (_cnt > 1) {
            isDupl = true;
            return false;
          }
        });
        if (isDupl) {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSG0001008', /* 중복된 공정/자재가 있습니다. */
            type: 'warning', // success / info / warning / error
          });
          return false;
        }
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        this.$_.forEach(saveData, item => {
          item.regUserId = this.$store.getters.user.userId;
          item.chgUserId = this.$store.getters.user.userId;
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다.
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    closePopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.grid.data.push({
            editFlag: 'C',
            equipmentMaterialCd: uid(),
            equipmentCd: this.param.equipmentCd,
            processCd: '',
            materialCd: item.materialCd,
            materialName: item.materialName,
            chemMaterialFlag: item.chemMaterialFlag,
            chemDailyVolume: '',
            chemDailyHour: '',
          })
        });
      }
    },
    addrow() {
      // this.popupOptions.title = 'LBL0003329'; // 자재 검색
      // this.popupOptions.param = {
      //   type: 'multiple',
      // };
      // this.popupOptions.target = () => import(`${'@/pages/common/material/materialPop.vue'}`);
      // this.popupOptions.width = '40%';
      // this.popupOptions.visible = true;
      // this.popupOptions.closeCallback = this.closePopup;
    },
    deleteMat() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>